import { MaxUint256 } from '@pancakeswap/swap-sdk-core'
import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, ERC20Token } from '@pancakeswap/sdk'
import { useToast } from '@pancakeswap/uikit'
import { useAccount, Address } from 'wagmi'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isUserRejected, logError } from 'utils/sentry'
import { SendTransactionResult } from 'wagmi/actions'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils'
import isUndefinedOrNull from '@pancakeswap/utils/isUndefinedOrNull'
import useGelatoLimitOrdersLib from './limitOrders/useGelatoLimitOrdersLib'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { useTokenContract } from './useContract'
import useTokenAllowance from './useTokenAllowance'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// Returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string,
  options: {
    addToTransaction: boolean
    targetAmount?: bigint
    useExactAmount?: boolean // New option to control exact amount approval
  } = {
    addToTransaction: true,
    useExactAmount: false, // Default to exact amount approval
  },
): {
  approvalState: ApprovalState
  approveCallback: () => Promise<SendTransactionResult>
  revokeCallback: () => Promise<SendTransactionResult>
  currentAllowance: CurrencyAmount<Currency> | undefined
  isPendingError: boolean
} {
  const { addToTransaction = true, targetAmount, useExactAmount = false } = options
  const { address: account } = useAccount()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const { toastError } = useToast()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined
  const { allowance: currentAllowance, refetch } = useTokenAllowance(token, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)
  const [pending, setPending] = useState<boolean>(pendingApproval)
  const [isPendingError, setIsPendingError] = useState<boolean>(false)

  useEffect(() => {
    if (pendingApproval) {
      setPending(true)
    } else if (pending) {
      refetch().then(() => {
        setPending(false)
      })
    }
  }, [pendingApproval, pending, refetch])

  // Check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency?.isNative) return ApprovalState.APPROVED
    // We might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pending
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pending, spender])

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(
    async (overrideAmountApprove?: bigint): Promise<SendTransactionResult> => {
      // Initial validation checks
      if (approvalState !== ApprovalState.NOT_APPROVED && isUndefinedOrNull(overrideAmountApprove)) {
        toastError(t('Error'), t('Approve was called unnecessarily'))
        setIsPendingError(true)
        return undefined
      }

      if (!token) {
        console.error('No token provided for approval')
        setIsPendingError(true)
        return undefined
      }

      if (!tokenContract) {
        toastError(t('Error'), t('Cannot find contract of the token %tokenAddress%', { tokenAddress: token?.address }))
        setIsPendingError(true)
        return undefined
      }

      if (!amountToApprove && isUndefinedOrNull(overrideAmountApprove)) {
        toastError(t('Error'), t('Missing amount to approve'))
        setIsPendingError(true)
        return undefined
      }

      if (!spender) {
        toastError(t('Error'), t('No spender'))
        setIsPendingError(true)
        return undefined
      }

      let approvalAmount: bigint
      if (overrideAmountApprove !== undefined) {
        approvalAmount = overrideAmountApprove
      } else if (useExactAmount) {
        approvalAmount = amountToApprove?.quotient ?? targetAmount ?? 0n
      } else {
        approvalAmount = MaxUint256
      }

      try {
        // Estimate gas for the approval
        const estimatedGas = await tokenContract.estimateGas
          .approve([spender as Address, approvalAmount], {
            account: tokenContract.account,
          })
          .catch((error) => {
            console.error('Failed to estimate gas for approval', error)
            toastError(t('Error'), t('Unexpected error. Could not estimate gas for the approve.'))
            setIsPendingError(true)
            return null
          })

        if (!estimatedGas) return undefined

        // Execute the approval transaction
        const response = await callWithGasPrice(
          tokenContract,
          'approve' as const,
          [spender as Address, approvalAmount],
          {
            gas: calculateGasMargin(estimatedGas),
          },
        )

        if (addToTransaction) {
          addTransaction(response, {
            summary: `Approve ${amountToApprove?.currency?.symbol}`,
            translatableSummary: {
              text: 'Approve %symbol%',
              data: { symbol: amountToApprove?.currency?.symbol },
            },
            approval: { 
              tokenAddress: token?.address, 
              spender,
            },
            type: 'approve',
          })
        }

        return response
      } catch (error: any) {
        logError(error)
        console.error('Failed to approve token', error)
        if (!isUserRejected(error)) {
          toastError(t('Error'), error.message)
        }
        setIsPendingError(true)
        throw error
      }
    },
    [
      approvalState,
      token,
      tokenContract,
      amountToApprove,
      spender,
      callWithGasPrice,
      targetAmount,
      useExactAmount,
      toastError,
      t,
      addToTransaction,
      addTransaction,
    ],
  )

  const approveCallback = useCallback(() => {
    setPending(true)
    return approve()
      .catch((error) => {
        setPending(false)
        throw error
      })
  }, [approve])

  const revokeCallback = useCallback(() => {
    return approve(0n)
  }, [approve])

  return { approvalState, approveCallback, revokeCallback, currentAllowance, isPendingError }
}

// Helper hook for approving from amount
export function useApproveCallbackFromAmount({
  token,
  minAmount,
  targetAmount,
  spender,
  addToTransaction,
  useExactAmount = false,
}: {
  token?: ERC20Token
  minAmount?: bigint
  targetAmount?: bigint
  spender?: string
  addToTransaction?: boolean
  useExactAmount?: boolean
}) {
  const amountToApprove = useMemo(() => {
    if (!minAmount || !token) return undefined
    return CurrencyAmount.fromRawAmount(token, minAmount)
  }, [minAmount, token])

  return useApproveCallback(amountToApprove, spender, {
    addToTransaction,
    targetAmount,
    useExactAmount,
  })
}

// Wrapper for Gelato Limit Orders
export function useApproveCallbackFromInputCurrencyAmount(
  currencyAmountIn: CurrencyAmount<Currency> | undefined,
  useExactAmount = false,
) {
  const gelatoLibrary = useGelatoLimitOrdersLib()

  return useApproveCallback(currencyAmountIn, gelatoLibrary?.erc20OrderRouter?.address ?? undefined, {
    addToTransaction: true,
    useExactAmount,
  })
}