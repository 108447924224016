import { Currency, CurrencyAmount, TradeType, Percent, ONE_HUNDRED_PERCENT } from '@pancakeswap/sdk'
import { SmartRouterTrade, SmartRouter } from '@pancakeswap/smart-router/evm'
import { FeeAmount } from '@pancakeswap/v3-sdk'

import { BIPS_BASE, INPUT_FRACTION_AFTER_FEE } from 'config/constants/exchange'
import { useDexScreenerPrice } from 'hooks/useBUSDPrice';

// Computes price breakdown for the trade based on $ value change
export function useTradePriceBreakdown(trade?: SmartRouterTrade<TradeType> | null): {
    valueOutUsd?: number | null
    priceImpactWithoutFee?: Percent | null
    lpFeeAmount?: CurrencyAmount<Currency> | null
} {

    //USE Dexscreener price

    // Fetch DexScreener prices for input and output tokens
    const tokenInPrice = useDexScreenerPrice(
        trade?.inputAmount?.currency, 
        1, 
        trade?.inputAmount?.currency.chainId
    );
    const tokenOutPrice = useDexScreenerPrice(
        trade?.outputAmount?.currency, 
        1, 
        trade?.outputAmount?.currency.chainId
    );

    // If the trade object is not provided, return undefined values.
    if (!trade) {
        return {
            priceImpactWithoutFee: undefined,
            lpFeeAmount: null,
        };
    }

    const { routes, outputAmount, inputAmount } = trade;

    // Calculate the fee percentage across all routes.
    let feePercent = new Percent(0);
    for (const route of routes) {
        const { pools, percent } = route;
        const routeFeePercent = ONE_HUNDRED_PERCENT.subtract(
            pools.reduce<Percent>((currentFee, pool) => {
                if (SmartRouter.isV2Pool(pool)) {
                    return currentFee.multiply(INPUT_FRACTION_AFTER_FEE);
                }
                if (SmartRouter.isStablePool(pool)) {
                    return currentFee.multiply(ONE_HUNDRED_PERCENT.subtract(pool.fee));
                }
                if (SmartRouter.isV3Pool(pool)) {
                    return currentFee.multiply(ONE_HUNDRED_PERCENT.subtract(v3FeeToPercent(pool.fee)));
                }
                return currentFee;
            }, ONE_HUNDRED_PERCENT),
        );

        // Not accurate since for stable swap, the LP fee is deducted on the output side.
        feePercent = feePercent.add(routeFeePercent.multiply(new Percent(percent, 100)));
    }

    //const lpFeeAmount = inputAmount.multiply(feePercent);
    const lpFeeAmount = outputAmount.multiply(feePercent);

    // Ensure the prices are available and not loading before proceeding.
    if (tokenInPrice.isLoading || tokenOutPrice.isLoading || !tokenInPrice.price || !tokenOutPrice.price) {
        return {
            priceImpactWithoutFee: new Percent(0, 100),
            lpFeeAmount,
        };
    }

    // Calculate the USD values of the input and output tokens.
    const valueInUsd = tokenInPrice.price * parseFloat(inputAmount.toSignificant());
    const valueOutUsd = tokenOutPrice.price * parseFloat(outputAmount.toSignificant());

    // Calculate price impact based on the difference in value.
    const priceImpactRaw = (valueInUsd - valueOutUsd) / valueInUsd;
    const priceImpactPercent = new Percent(
        BigInt(Math.round(priceImpactRaw * 10000)), // Multiply by 10000 to preserve decimal precision.
        BigInt(10000) // Use 10000 as the denominator for percent calculation.
    );
    const priceImpactWithoutFee = priceImpactPercent.subtract(feePercent);

    // Commented out the old way of calculating price impact
    // const priceImpactWithoutFee = new Percent(0) // temp fix due to low lp 

    return {
        valueOutUsd,
        priceImpactWithoutFee,
        lpFeeAmount,
    };
}

// Helper function to convert v3 fee to a Percent object.
export function v3FeeToPercent(fee: FeeAmount): Percent {
    return new Percent(fee, BIPS_BASE * 100n);
}