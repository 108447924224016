import React from 'react'
import Marquee from 'react-fast-marquee'
import { CurrencyLogo, Flex, Text } from '@pancakeswap/uikit'
import { Currency, Token } from '@pancakeswap/sdk'
import styled from 'styled-components'
import useTokenBoosts from 'hooks/useTokenBoosts'
// import { getCurrentCompetition } from 'views/Leaderboard'


interface TokenBoostResponse {
  tokens: {
    currency: Currency
    boost: number
  }[]
}

interface TokensBoostProps {
  chainId: number
}

// Styled components
const StyledMarquee = styled(Marquee)`
margin-bottom: 5px;
width: 100%;
max-width: 480px;
mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
-webkit-mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
`

export const TokensBoost: React.FC<TokensBoostProps> = ({ chainId }) => {
  const { boosts, isLoading, isError } = useTokenBoosts(chainId);
 // const currentCompetition = getCurrentCompetition(Number(chainId));

 //   if (!currentCompetition || isLoading || isError) { 
 //     return null;
   // }
//
  return (
    <StyledMarquee>
      {boosts.map((token) => {
        const address = token.currency.isToken? token.currency.address : null;
        const tokenData = new Token(
          chainId,
          address,
          token.currency.decimals,
          token.currency.symbol
        )

        return (
          <Flex key={address} alignItems="center" mr="24px" gap="3px">
            <CurrencyLogo currency={tokenData} size="16px" />
            <Text fontSize="12px">{token.currency.symbol}</Text>
            <Text fontSize="12px" color="success" ml="2px">
              {token.boost}% Boost
            </Text>
          </Flex>
        )
      })}
    </StyledMarquee>
  )
}

export default TokensBoost