import { useTranslation } from '@pancakeswap/localization'
import { Route, SmartRouter, V2Pool } from '@pancakeswap/smart-router/evm'
import { Box, Flex, IconButton, QuestionHelper, SearchIcon, Text, useModalV2 } from '@pancakeswap/uikit'
import { styled } from 'styled-components'
import { memo } from 'react'

import { RowBetween } from 'components/Layout/Row'
import SwapRoute from 'views/Swap/components/SwapRoute'
import { RouteDisplayModal } from './RouteDisplayModal'

interface Props {
  routes?: Route[]
}

const RouteInfoContainer = styled.div`
  padding: 4px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RoutesBreakdown = memo(function RoutesBreakdown({ routes = [] }: Props) {
  const { t } = useTranslation()
  const routeDisplayModal = useModalV2()

  if (!routes.length) {
    return null
  }

  const count = routes.length
  const { pools } = routes[0]
  const pool = pools[0]
  const isV2Pool = SmartRouter.isV2Pool(pool)
  const uniqueExchanges = new Set(
    routes
      .map(route => {
        const pool = route.pools[0];
        return SmartRouter.isV2Pool(pool) ? pool.exchange : null;
      })
      .filter(exchange => exchange) // Remove null values
  );
  
  const exchange = (isV2Pool ? pool.exchange || 'Obsidian' : '') + (uniqueExchanges.size > 1 ? ' +1' : '');

  return (
    <>
      <RouteInfoContainer>
        <RowContainer>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Text fontSize={["11px", "14px" , "14px", "14px" ]} color="textSubtle">
              {t('Best DEX:')}
            </Text>
          </span>
          <Flex>
            <Text fontSize={["11px", "12px" , "12px", "12px" ]} marginLeft={2}>
              {exchange}
            </Text>
          </Flex>
        </RowContainer>
        
        <RowContainer>
          <Text fontSize={["11px", "14px" , "14px", "14px" ]} color="textSubtle">
            {t('Best Path:')}
          </Text>
          <Box onClick={routeDisplayModal.onOpen} role="button">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {count > 1 ? (
                <Text fontSize={["11px", "14px" , "14px", "14px" ]}>{t('%count% Separate Routes', { count })}</Text>
              ) : (
                <RouteComp route={routes[0]} />
              )}
              <IconButton ml="8px" variant="text" color="textSubtle" scale="xs">
                <SearchIcon width="16px" height="16px" color="textSubtle" />
              </IconButton>
            </span>
          </Box>
        </RowContainer>
        <RouteDisplayModal {...routeDisplayModal} routes={routes} />
      </RouteInfoContainer>
    </>
  )
})

interface RouteProps {
  route: Route
}

function RouteComp({ route }: RouteProps) {
  const { path } = route

  return (
    <RowBetween>
      <SwapRoute path={path} />
    </RowBetween>
  )
}